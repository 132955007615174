import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from './Context/AuthContext';
import Back from "./Back"; // Adjust the import path as necessary

const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const { login } = useAuth(); // Destructure the login function

const handleSubmit = async (event) => {
  event.preventDefault();
  const data = new FormData(event.currentTarget);
  const username = data.get('username'); // Ensure this matches the name attribute of your input field
  const password = data.get('password');

  try {
    const response = await axios.post('https://cottonclub.azurewebsites.net/api/login', { username, password }, {
      withCredentials: true
    });
    alert(response.data.message+" "+response.status);
    if (response.status === 200) {
      console.log(response.data.message);
      const isAdmin = response.data.isAdmin;
      login({ username, isAdmin });
      navigate('/'); // Redirect the user to the main page on successful login
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    const errorMessage = error.response?.data.message || error.message;
    alert(`Login failed: ${errorMessage}`);
    console.error(`Login failed: ${errorMessage}`);
  }
};


  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Back></Back>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
