import React, { useState } from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import { Pie, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import Back from "./Back";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Tooltip,
  Legend
);

const Analytics = () => {
  const [file, setFile] = useState(null);
  const [results, setResults] = useState(null);
  const [selectedChart, setSelectedChart] = useState(null);
  const [chartType, setChartType] = useState(null);
  const [message, setMessage] = useState('');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
    } else {
      alert("Please select a CSV file.");
      event.target.value = ""; // Reset the file input
    }
  };

  const uploadAndAnalyzeFile = () => {
    if (!file) {
      alert("No file selected or file type is not CSV.");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    axios.post('https://cottonclubweb.azurewebsites.net/api/upload-and-analyze', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      const data = JSON.parse(response.data);
      setResults(data);
      setDateRange({ start: data.start_date, end: data.end_date });
      alert('File analyzed successfully');
    })
    .catch(error => {
      console.error(error);
      alert('Error analyzing file');
    });
  };

  const renderPieChart = (data, title) => {
    if (!data) return null;

    const chartData = {
      labels: Object.keys(data),
      datasets: [
        {
          label: title,
          data: Object.values(data),
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E7E9ED', '#4BC0C0'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E7E9ED', '#4BC0C0'],
        },
      ],
    };

    const options = {
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed !== undefined) {
                const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
                const percentage = ((context.parsed / total) * 100).toFixed(2) + '%';
                label += `${context.parsed} (${percentage})`;
              }
              return label;
            }
          }
        }
      }
    };

    return <Pie data={chartData} options={options} />;
  };

  const renderHistogram = (data, title) => {
    if (!data) return null;

    const chartData = {
      labels: Object.keys(data),
      datasets: [
        {
          label: title,
          data: Object.values(data),
          backgroundColor: '#36A2EB',
        },
      ],
    };

    const options = {
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  const displayResults = (type) => {
    if (!results) {
      setMessage("No results to display. Please upload and analyze a file first.");
      return;
    }

    setSelectedChart(type);
    setChartType(null);
    setMessage('');

    if (["average_payment", "average_payment_excluding_zeros", "refund_percentage", "number_of_refunds"].includes(type)) {
      const formattedTitle = type.replace(/_/g, ' ');
      const value = results[type];
      if (value !== undefined) {
        setMessage(`${formattedTitle}: ${value}`);
      } else {
        setMessage("Data not available");
      }
    } else {
      setSelectedChart(type); // Set the selected chart type to render
      setMessage(''); // Clear message when displaying charts
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>Shopify Analytics</Typography>
      <Back />
      <Box>
        <input type="file" onChange={handleFileChange} accept=".csv" />
        <Button variant="contained" onClick={uploadAndAnalyzeFile} sx={{ mt: 2 }}>Upload and Analyze</Button>
      </Box>
      {dateRange.start && dateRange.end && (
        <Typography color="textSecondary" sx={{ mt: 2 }}>
          Date Range: {dateRange.start} - {dateRange.end}
        </Typography>
      )}
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
        <Button variant="contained" onClick={() => displayResults("top_5_products")}>Top 5 Products</Button>
        <Button variant="contained" onClick={() => displayResults("top_5_discount_codes")}>Top 5 Discount Codes</Button>
        <Button variant="contained" onClick={() => displayResults("average_payment")}>Average Payment</Button>
        <Button variant="contained" onClick={() => displayResults("average_payment_excluding_zeros")}>Average Payment (Excluding Zeros)</Button>
        <Button variant="contained" onClick={() => displayResults("refund_percentage")}>Refund Percentage</Button>
        <Button variant="contained" onClick={() => displayResults("number_of_refunds")}>Number of Refunds</Button>
      </Box>
      {message && <Typography color="textSecondary">{message}</Typography>}
      {selectedChart && results[selectedChart] && (
        <>
          {(selectedChart === "top_5_products" || selectedChart === "top_5_discount_codes") && (
            <Box sx={{ mt: 4 }}>
              <Button variant="contained" onClick={() => setChartType('pie')} sx={{ mr: 2 }}>
                Pie Chart
              </Button>
              <Button variant="contained" onClick={() => setChartType('histogram')}>
                Histogram
              </Button>
            </Box>
          )}
          {chartType === 'pie' && renderPieChart(results[selectedChart], selectedChart.replace(/_/g, ' '))}
          {chartType === 'histogram' && renderHistogram(results[selectedChart], selectedChart.replace(/_/g, ' '))}
        </>
      )}
    </Container>
  );
};

export default Analytics;
