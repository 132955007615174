import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Button, FormControl, FormGroup, Fab, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Back from './Back';

function Refunded() {
    const [excelFile, setExcelFile] = useState(null);
    const [csvFile, setCsvFile] = useState(null);
    const excelFileInputRef = useRef();
    const csvFileInputRef = useRef();

    const handleExcelFileChange = (e) => {
        setExcelFile(e.target.files[0]);
    };

    const handleCsvFileChange = (e) => {
        setCsvFile(e.target.files[0]);
    };

    const handleExcelFileInputClick = () => {
        excelFileInputRef.current.click();
    };

    const handleCsvFileInputClick = () => {
        csvFileInputRef.current.click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        if (excelFile) formData.append('excel_file', excelFile);
        if (csvFile) formData.append('csv_file', csvFile);

        try {
            const response = await axios.post('https://cottonclubweb.azurewebsites.net/api/refunded', formData, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'modified_excel.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            alert('Error:', error);
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <Back />
            <h1>קבצי זיכויים</h1>
            <form onSubmit={handleSubmit}>
                <FormGroup row style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <FormControl style={{ margin: '10px', textAlign: 'center' }}>
                        <Typography variant="subtitle1">Excel File</Typography>
                        <Fab color="primary" aria-label="add" onClick={handleExcelFileInputClick}>
                            <AddIcon />
                        </Fab>
                        <input
                            id="excel-file-input"
                            type="file"
                            accept=".xls,.xlsx"
                            onChange={handleExcelFileChange}
                            style={{ display: 'none' }}
                            ref={excelFileInputRef}
                        />
                        {excelFile && <Typography variant="caption">{excelFile.name}</Typography>}
                    </FormControl>

                    <FormControl style={{ margin: '10px', textAlign: 'center' }}>
                        <Typography variant="subtitle1">CSV File</Typography>
                        <Fab color="primary" aria-label="add" onClick={handleCsvFileInputClick}>
                            <AddIcon />
                        </Fab>
                        <input
                            id="csv-file-input"
                            type="file"
                            accept=".csv"
                            onChange={handleCsvFileChange}
                            style={{ display: 'none' }}
                            ref={csvFileInputRef}
                        />
                        {csvFile && <Typography variant="caption">{csvFile.name}</Typography>}
                    </FormControl>

                    <Button variant="contained" color="primary" type="submit" style={{ margin: '10px' }}>
                        Process and Download
                    </Button>
                </FormGroup>
            </form>

            <Button
                variant="contained"
                color="secondary"
                href={process.env.PUBLIC_URL + '/assets/FormatsFiles/refunded2.xlsx'}
                download="refunded2.xlsx"
                style={{ margin: '10px' }}
            >
                Download Format
            </Button>
        </div>
    );
}

export default Refunded;
