import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import 'moment-timezone';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import './WorkCalendar.css'
moment.tz.setDefault('Asia/Jerusalem');
const localizer = momentLocalizer(moment);
function WorkCalendar() {
  const [myEvents, setMyEvents] = useState([]);
  const [newTask, setNewTask] = useState({
    title: '',
    start: '',
    end: '',
    resourceId: 1 // default value or make it selectable
  });


  const [taskAdded, setTaskAdded] = useState(false); // New state variable
  const [file, setFile] = useState(null); // New state for the file
  const [currentView, setCurrentView] = useState(localStorage.getItem('calendarView') || 'week');
  const handleViewChange = (view) => {
    setCurrentView(view);
    localStorage.setItem('calendarView', view);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Update the file state
  };
  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', file); // Append file to form data

    axios.post('https://cottonclubweb.azurewebsites.net/api/upload-schedule', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      // Assuming the response is the list of events
      const events = response.data.map(event => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end)
      }));
      setMyEvents(events); // Update the events in the my_calendar
    })
    .catch(error =>
      {
      console.error('Error uploading file:', error)
      alert (error);
  });
  };


  useEffect(() => {
    axios.get('https://cottonclubweb.azurewebsites.net/api/tasks')
      .then(response => {
        setMyEvents(response.data.map(event => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end)
        })));
      })
      .catch(error => console.error('Error fetching events:', error));
  }, [taskAdded]);

  const onEventDrop = ({ event, start, end }) => {
    const updatedEvents = myEvents.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    setMyEvents(updatedEvents);

    axios.post('https://cottonclubweb.azurewebsites.net/api/change_date', {
      id: event.id,
      start: start,
      end: end
    })
    .then(response => console.log(response.data))
    .catch(error => console.error('Error updating event:', error));
  };
  const handleInputChange = (e) => {
    setNewTask({ ...newTask, [e.target.name]: e.target.value });
  };

  const addNewTask = () => {
    axios.post('https://cottonclubweb.azurewebsites.net/api/add', {
      title: newTask.title,
      start: newTask.start, // Make sure this is in ISO format
      end: newTask.end, // Make sure this is in ISO format
      resourceId: newTask.resourceId
    })
    .then(response => {
      console.log('Task added:', response.data);
      setTaskAdded(true);
    })
    .catch(error => console.error('Error adding new task:', error));
  };
  const deleteTask = (taskId) => {
    // Confirm before deleting
    if (window.confirm("Are you sure you want to delete this task?")) {
      axios.post('https://cottonclubweb.azurewebsites.net/api/delete_task_by_id', { id: taskId })
        .then(response => {
          console.log('Task deleted:', response.data);
          alert("Task successfully deleted."); // Alert after successful deletion
          setTaskAdded(!taskAdded); // Refresh the tasks list
        })
        .catch(error => {
          console.error('Error deleting task:', error);
          alert("Failed to delete the task."); // Alert if there's an error
        });
    }
  };

  const EventPropGetteres = ({ event, deleteTask }) => (
    <div style={eventStyle}>
      <span>{event.title}</span>
      <DeleteIcon
        style={deleteIconStyle}
        onClick={(e) => {
          e.stopPropagation(); // Prevent my_calendar event click
          deleteTask(event.id); // Directly call deleteTask here
        }}
      />
    </div>
  );

  const deleteIconStyle = {
    position: 'absolute',
    top: '5px', // Adjust as needed
    right: '5px', // Adjust as needed
    cursor: 'pointer'
  };
  const eventStyle = {
    position: 'relative',
    // Other styles for your event
  };


  const DragAndDropCalendar = withDragAndDrop(Calendar);

  const views = useMemo(() => ({
    month: true,
    week: true,
    day: true,
  }), []);

  return (
    <div className="myCustomCalendar">
      <div>
        <input type="text" name="title" placeholder="Title" value={newTask.title} onChange={handleInputChange} />
        <input type="datetime-local" name="start" value={newTask.start} onChange={handleInputChange} />
        <input type="datetime-local" name="end" value={newTask.end} onChange={handleInputChange} />
        <button onClick={addNewTask}>Add Task</button>
      </div>
      <div>
        <input type="file" accept=".xls,.xlsx" onChange={handleFileChange} />
        <button onClick={uploadFile}>Upload Schedule</button>
      </div>
      <DragAndDropCalendar
        localizer={localizer}
        events={myEvents}
        views={views}
        startAccessor="start"
        endAccessor="end"
        defaultView={currentView}
        onView={handleViewChange}
        onEventDrop={onEventDrop}
        onEventResize ={onEventDrop}
        components={{
          event: (props) => <EventPropGetteres {...props} deleteTask={deleteTask} />,
        }}
      />
    </div>
  );
}

export default WorkCalendar;