import React, { useState, useRef } from 'react';
import { Button, Container, Typography, Box, Fab, FormControl } from '@mui/material';
import axios from 'axios';
import { Pie, Bar } from 'react-chartjs-2';
import AddIcon from '@mui/icons-material/Add';
import Back from './Back';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

const VendorAnalytics = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [chartType, setChartType] = useState('pie'); // State to manage chart type
  const fileInputRef = useRef();

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const uploadFiles = () => {
    if (!selectedFiles) return;

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    setLoading(true);
    axios.post('https://cottonclubweb.azurewebsites.net/api/vendor_analytics', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      setData(response.data);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error uploading files:', error);
      setLoading(false);
    });
  };

  const handleAnalysisSelection = (analysis) => {
    setSelectedAnalysis(analysis);
    if (analysis === 'top_buyer_per_product') {
      setSelectedMonth('all');
    } else {
      setSelectedMonth(null);
    }
    setSelectedCustomer(null);
  };

  const handleCustomerSelection = (customer) => {
    setSelectedCustomer(customer);
  };

  const renderPieChart = () => {
    let analysisData;

    if (selectedAnalysis === 'top_buyer_per_product') {
      analysisData = data[selectedAnalysis];
    } else if (data && selectedAnalysis && selectedMonth && data[selectedAnalysis] && data[selectedAnalysis][selectedMonth] && !data[selectedAnalysis][selectedMonth][selectedCustomer]) {
      analysisData = data[selectedAnalysis][selectedMonth];
    } else if (data[selectedAnalysis][selectedMonth][selectedCustomer]) {
      analysisData = data[selectedAnalysis][selectedMonth][selectedCustomer];
    } else {
      console.error('Data is not in the expected format:', data);
      return null;
    }

    if (!Array.isArray(analysisData)) {
      console.error('Expected analysisData to be an array, received:', analysisData);
      return null;
    }

    let labels = null;
    if (selectedAnalysis === 'monthly_sales_per_customer') {
      labels = analysisData.map(item => item['מס. לקוח'].toString());
    } else {
      labels = analysisData.map(item => item['תאור מוצר'].toString());
    }

    const quantities = analysisData.map(item => item['כמות']);
    const totalQuantity = quantities.reduce((acc, value) => acc + value, 0);

    const chartData = {
      labels,
      datasets: [{
        label: `${selectedAnalysis}${selectedMonth !== 'all' ? ` for ${selectedMonth}` : ''}`,
        data: quantities,
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#E7E9ED', '#4BC0C0',
          '#FF9F40', '#FFCD56', '#4BC0C0', '#36A2EB', '#9966FF'
        ],
        hoverBackgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#E7E9ED', '#4BC0C0',
          '#FF9F40', '#FFCD56', '#4BC0C0', '#36A2EB', '#9966FF'
        ],
      }],
    };

    const options = {
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              let label = chartData.labels[tooltipItem.dataIndex] || '';
              if (label) {
                label += ': ';
              }
              const currentValue = chartData.datasets[0].data[tooltipItem.dataIndex];
              const percentage = ((currentValue / totalQuantity) * 100).toFixed(2);
              label += currentValue + ` (${percentage}%)`;
              return label;
            }
          }
        }
      }
    };

    return <Pie data={chartData} options={options} />;
  };

  const renderHistogram = () => {
    let analysisData;

    if (selectedAnalysis === 'top_buyer_per_product') {
      analysisData = data[selectedAnalysis];
    } else if (data && selectedAnalysis && selectedMonth && data[selectedAnalysis] && data[selectedAnalysis][selectedMonth] && !data[selectedAnalysis][selectedMonth][selectedCustomer]) {
      analysisData = data[selectedAnalysis][selectedMonth];
    } else if (data[selectedAnalysis][selectedMonth][selectedCustomer]) {
      analysisData = data[selectedAnalysis][selectedMonth][selectedCustomer];
    } else {
      console.error('Data is not in the expected format:', data);
      return null;
    }

    if (!Array.isArray(analysisData)) {
      console.error('Expected analysisData to be an array, received:', analysisData);
      return null;
    }

    let labels = null;
    if (selectedAnalysis === 'monthly_sales_per_customer') {
      labels = analysisData.map(item => item['מס. לקוח'].toString());
    } else {
      labels = analysisData.map(item => item['תאור מוצר'].toString());
    }

    const quantities = analysisData.map(item => item['כמות']);

    const chartData = {
      labels,
      datasets: [{
        label: `${selectedAnalysis}${selectedMonth !== 'all' ? ` for ${selectedMonth}` : ''}`,
        data: quantities,
        backgroundColor: '#36A2EB',
      }],
    };

    const options = {
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  return (
    <Container maxWidth="lg">
      <Back />
      <Typography variant="h4" component="h1" gutterBottom>
        Analytics Dashboard
      </Typography>
      <FormControl style={{textAlign: 'center' }}>
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Box mr={2}>
            <Fab color="primary" aria-label="add" onClick={handleFileInputClick}>
              <AddIcon />
            </Fab>
          </Box>
          <input
            type="file"
            accept=".xls,.xlsx"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          {selectedFiles && (
            <Typography variant="caption">
              {Array.from(selectedFiles).map(file => file.name).join(', ')}
            </Typography>
          )}
          <Button variant="contained" onClick={uploadFiles} disabled={loading} style={{ marginRight: '10px' }}>
            {loading ? 'Uploading...' : 'Upload and Fetch Data'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            href={process.env.PUBLIC_URL + '/assets/FormatsFiles/vendorAnalytics.xlsx'}
            download="vendorAnalytics.xlsx"
          >
            Download Format
          </Button>
        </Box>
      </FormControl>

      <Box sx={{ mt: 4 }}>
        <Button variant="contained" onClick={() => handleAnalysisSelection('top_10_selling_products_per_month')} disabled={!data['top_10_selling_products_per_month']}>
          Top 10 Selling Products Per Month
        </Button>
        <Button variant="contained" onClick={() => handleAnalysisSelection('top_10_selling_products_per_customer_per_month')} disabled={!data['top_10_selling_products_per_customer_per_month']}>
          Top 10 Selling Products Per Customer Per Month
        </Button>
        <Button variant="contained" onClick={() => handleAnalysisSelection('top_buyer_per_product')} disabled={!data['top_buyer_per_product']}>
          Top Buyer Per Product
        </Button>
        <Button variant="contained" onClick={() => handleAnalysisSelection('monthly_sales_per_customer')} disabled={!data['monthly_sales_per_customer']}>
          Monthly Sales Per Customer
        </Button>
      </Box>
      {selectedAnalysis && selectedAnalysis !== 'top_buyer_per_product' && (
        <Box sx={{ mt: 4 }}>
          {Object.keys(data[selectedAnalysis] || {}).map((month) => (
            <Button
              key={month}
              variant="contained"
              onClick={() => setSelectedMonth(month)}
              sx={{ mr: 2, mb: 2 }}
            >
              {month}
            </Button>
          ))}
        </Box>
      )}

      {selectedAnalysis === 'top_10_selling_products_per_customer_per_month' && selectedMonth && (
        <Box sx={{ mt: 4 }}>
          {Object.keys(data[selectedAnalysis][selectedMonth] || {}).map((customer) => (
            <Button
              key={customer}
              variant="contained"
              onClick={() => handleCustomerSelection(customer)}
              sx={{ mr: 2, mb: 2 }}
            >
              Customer {customer}
            </Button>
          ))}
        </Box>
      )}

      {selectedMonth && selectedAnalysis && (
        <Box sx={{ mt: 4 }}>
          <Button variant="contained" onClick={() => setChartType('pie')} sx={{ mr: 2 }}>
            Pie Chart
          </Button>
          <Button variant="contained" onClick={() => setChartType('histogram')}>
            Histogram
          </Button>
        </Box>
      )}

      <Box sx={{ mt: 4 }}>
        {selectedMonth && (chartType === 'pie' ? renderPieChart() : renderHistogram())}
      </Box>
    </Container>
  );
};

export default VendorAnalytics;
