import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './Context/AuthContext'; // Assuming useAuth is correctly exported

const PrivateAdminRoute = ({ children }) => {
  const { currentUser, isAdmin } = useAuth(); // Use useAuth hook to access the current user and isAdmin

  // Check both authentication and admin status
  // Ensure currentUser exists and isAdmin function returns true for admin users
  if (currentUser && isAdmin()) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateAdminRoute;
