import React, { createContext, useContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Attempt to retrieve the user from local storage
    const user = localStorage.getItem('user');
    if (user) {
      setCurrentUser(JSON.parse(user));
    }
  }, []);

  const login = (user) => {
    // Here, ensure that the user object includes an isAdmin property
    // For example, user might look like: { username: "john", isAdmin: true }
    localStorage.setItem('user', JSON.stringify(user));
    setCurrentUser(user);
  };

  const logout = () => {
    localStorage.removeItem('user');
    setCurrentUser(null);
  };

  // Optionally, you could add a method to specifically check admin status
  const isAdmin = () => {
    return currentUser && currentUser.isAdmin;
  };

  const value = {
    currentUser,
    login,
    logout,
    isAdmin
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
