import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    FormLabel,
    RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Back from "./Back";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState('no'); // 'yes' or 'no'

  useEffect(() => {
    fetchUsers();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://cottonclubweb.azurewebsites.net/api/get_users');
      setUsers(response.data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const addUser = async () => {
    try {
      await axios.post('https://https://cottonclubweb.azurewebsites.net/api/register', { username, password, admin: isAdmin === 'yes' });
      setUsername('');
      setPassword('');
      setIsAdmin('no');
      alert("user successfully added");
      await fetchUsers(); // Re-fetch users to update the list after adding a user
    } catch (error) {
      console.error("Failed to add user:", error);
    }
  };

  const deleteUser = async (id) => {
    try {
      await axios.post('https://https://cottonclubweb.azurewebsites.net/api/remove_user', { id });
      await fetchUsers(); // Re-fetch users to update the list after deleting a user
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  return (
      <Container maxWidth="sm">
          {/* Inputs and Add User Button */}
          <Back></Back>
          <h1>ADMIN DASHBOARD</h1>
          <h2>הוסף משתמש:</h2>

          <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
          />
          <TextField
              label="Password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              type="password"
          />
                <FormLabel component="legend">Admin Access</FormLabel>
      <RadioGroup
        row
        aria-label="isAdmin"
        name="row-radio-buttons-group"
        value={isAdmin}
        onChange={(e) => setIsAdmin(e.target.value)}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
      <Button variant="contained" onClick={addUser} fullWidth>Add User</Button>
          {/* Users List */}
          <h2>רשימת משתמשים:</h2>

          <List>
              {users.map((user) => (
                  <ListItem
                      key={user.id}
                      secondaryAction={
                          <IconButton edge="end" aria-label="delete" onClick={() => deleteUser(user.id)}>
                              <DeleteIcon/>
                          </IconButton>
                      }
                  >
                      <ListItemText primary={user.username}/>
                  </ListItem>
              ))}
          </List>
      </Container>
  );
};

export default AdminDashboard;
