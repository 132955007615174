import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Context/AuthContext'; // Adjust the path as necessary

const cards = [
  { title: "זיכויים", description: "קובץ זיכויים. הכנס קובץ שופיפי csv וקובץ xls/xlsx מתאים", imageUrl: "/img/refunded.png" ,routeName:"./Refunded"},
  { title: "ספקים", description: "ספקים, הכנס קבצי xls/xlsx", imageUrl: "/img/vendor.png" ,routeName:"./Converter"},
  { title: "ספקים קארגו", description: "ספקים, הכנס קבצי xls/xlsx", imageUrl: "/img/vendor.png" ,routeName:"./ConverterCargo"},
  { title: "גאנט", description: "גאנט- ניתן להיכנס רק במשתמש אדמין", imageUrl: "img/shift.png" ,routeName:"./WorkCalendar"},
  { title: "אנליטיקה", description: "אנליטיקת אתר", imageUrl: "img/analytics.png" ,routeName:"./Analytics"},
  { title: "אנליטיקת ספקים", description: "אנליטיקת ספקים", imageUrl: "img/vendor_analytics.png" ,routeName:"./VendorAnalytics"},
  { title: "priority אנליטיקת ספקים", description: "priority אנליטיקת ספקים", imageUrl: "img/vendor_analytics.png" ,routeName:"./VendorAnalyticsPriority"},

]
const defaultTheme = createTheme();

export default function Album() {
    const navigate = useNavigate();
    const { currentUser, logout } = useAuth(); // Use the context
  const logOut = async () => {
    try {
      logout(); // Call logout from useAuth
      handleOpenClick('/signin');
    } catch (error) {
        alert(error.message);
      console.error("Log out failed:", error.response || error.message);
    }
  };
    const handleOpenClick = (route) => {
        navigate(route);
    };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
            <AppBar position="relative">
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        Cotton Club Tool
                    </Typography>
                    {currentUser ? (
                        <>
                            <Typography color="inherit" sx={{ marginRight: 2 }}>
                                {currentUser.username}
                            </Typography>
                            {currentUser.isAdmin && ( // Conditionally rendering the Admin Dashboard button
                                <Button color="inherit" onClick={() => handleOpenClick('/admin-dashboard')}>
                                    Admin Dashboard
                                </Button>
                            )}
                            <Button color="inherit" onClick={logOut}>Log Out</Button>
                        </>
                    ) : (
                        <>
                            <Button color="inherit" onClick={() => handleOpenClick('/signin')}>Sign In</Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
            CottonClub Tool Kit
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              אתר למועדון הכותנה לביצוע פעולות מהירות
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
            </Stack>
          </Container>
        </Box>
      <Container sx={{ py: 8 }} maxWidth="md">
        <Grid container spacing={4}>
          {cards.map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
              >
                <CardMedia
                  component="div"
                  sx={{ pt: '56.25%' }}
                  image={card.imageUrl}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.title}
                  </Typography>
                  <Typography>
                    {card.description}
                  </Typography>
                </CardContent>
                  <CardActions>
                      <Button onClick={() => handleOpenClick(`/${card.routeName}`)} size="small">Open</Button>
                  </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      </main>
    </ThemeProvider>
  );
}