import './App.css';
import './App.scss';
import Converter from './Converter.js';
import ConverterCargo from './ConverterCargo.js';
import Refunded from './Refunded.js';
import Album from './Album.js';
import {Route, Routes} from "react-router-dom";
import WorkCalendar from './WorkCalendar.js';
import Analytics from './Analytics.js';
import VendorAnalytics from './VendorAnalytics.js'
import VendorAnalyticsPriority from './VendorAnalyticsPriority.js'
import SignIn from "./SignIn.js";
import { AuthProvider } from './Context/AuthContext';
import AdminDashboard from './AdminDashboard'; // Admin-only component
import PrivateAdminRoute from './PrivateAdminRoute'; // Import your PrivateAdminRoute component
import NotFoundPage from './NotFoundPage.js';

function App() {
  return (
    <AuthProvider>
        <div className="App">
        <Routes>
            <Route path="/" element={<Album />} />
            <Route path="/Refunded" element={<Refunded />} />
            <Route path="/Converter" element={<Converter />} />
            <Route path="/ConverterCargo" element={<ConverterCargo />} />
            <Route path="/WorkCalendar" element={<WorkCalendar />} />
            <Route path="/Analytics" element={<Analytics />} />
            <Route path="/VendorAnalytics" element={<VendorAnalytics />} />
            <Route path="/VendorAnalyticsPriority" element={<VendorAnalyticsPriority />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/admin-dashboard" element={
              <PrivateAdminRoute>
                <AdminDashboard />
              </PrivateAdminRoute>
            } />
            <Route path="*" element={<NotFoundPage />} />
            {/* More routes as needed */}
        </Routes>
    </div>
    </AuthProvider>

  );
}


export default App;
