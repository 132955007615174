import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Button, FormControl, FormGroup, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InteractiveList from "./InteractiveList";
import Back from "./Back"; // Import useNavigate

function Converter() {
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef();

    const handleFileChange = (e) => {
        const filteredFiles = Array.from(e.target.files).filter(file =>
            file.name.endsWith('.xls') || file.name.endsWith('.xlsx')
        );
        setFiles(filteredFiles);
    };

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        files.forEach(file => {
            formData.append('file', file);
        });

        try {
            const response = await axios.post('https://cottonclubweb.azurewebsites.net/api/convert_files', formData, {
                responseType: 'blob',
            })
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'output.csv');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            alert(`Error: ${error.message}`);
            console.error('Error:', error.message);
        }
    };

    return (
        <div>
            <Back></Back>
            <h1>קבצי אשמורת</h1>
            <form onSubmit={handleSubmit}>
                <FormGroup row style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <FormControl>
                        <Fab color="primary" aria-label="add" onClick={handleFileInputClick}>
                            <AddIcon />
                        </Fab>
                        <input
                            id="file-input"
                            type="file"
                            multiple
                            accept=".xls,.xlsx"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                        />
                    </FormControl>
                    <Button variant="contained" color="primary" type="submit" style={{ marginLeft: '10px' }}>
                        Convert Files
                    </Button>
                </FormGroup>
                <InteractiveList files={files} setFiles={setFiles} />
            </form>
        </div>
    );
}

export default Converter;
