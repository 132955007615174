import React from 'react';
import { IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Box, Grid } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

function InteractiveList({ files, setFiles }) {
    const handleDelete = (file) => {
        setFiles(files.filter(f => f !== file));
    };

    const generate = (files) => {
        return files.map((file, index) => (
            <ListItem
                key={index}
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file)}>
                        <DeleteIcon />
                    </IconButton>
                }
            >
                <ListItemAvatar>
                    <Avatar>
                        <FolderIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={file.name}
                />
            </ListItem>
        ));
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Uploaded Files
                    </Typography>
                    <List>
                        {generate(files)}
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
}

export default InteractiveList;
