import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';

const Back = () => {
  const navigate = useNavigate();

  return (
    <IconButton onClick={() => navigate('/')} sx={{ position: 'absolute', top: 16, left: 16 }}>
      <ArrowBack />
    </IconButton>
  );
};

export default Back;
